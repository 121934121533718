import { Component, OnInit } from '@angular/core';
import { GameChannelService } from '../channels/game-channel.service';
import { PickChannelService } from '../channels/pick-channel.service';
import { PickingChannelService } from '../channels/picking-channel.service';
import { HistoryService } from './history.service';
import { PickHistory } from './pick-history';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss']
})
export class HistoryComponent implements OnInit {

  constructor(private _historyService : HistoryService,
    private _pickChannelService: PickingChannelService){}

  pickHistory: PickHistory[];
  pickChannel: any;
  displayedColumns = ['name', 'quantity', 'game'];

  ngOnInit(): void {
    this.loadHistory();
    this.setupPickChannel();
  }

  setupPickChannel(): void {
    this._pickChannelService.currentPicker$.subscribe(
      currentPicker => {
        this.loadHistory();
      });
  }

  loadHistory() : void {
    this._historyService.getHistory().subscribe((history : PickHistory[]) => {
      this.pickHistory = history;
    });
  }

  getImagePath(pick: PickHistory) {
    return '/assets/' + pick.mascot.toLowerCase() + '-logo.png';
  }
}

