import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Pick } from './pick';

@Injectable({
  providedIn: 'root'
})
export class PickService {


  constructor(private _http: HttpClient) { }
  savePicks(schedule_id: number, picks: Pick[]) {
    return Observable.create((o: any) => {
      this._http.post(environment.baseApi + 'pick', { 'schedule_id': schedule_id, 'picks': picks })
        .subscribe(
          (res: any) => {
            o.next(res);
            o.complete();
          },
          err => {
            //throwError(err);
            o.error(err);
          });
    });
  }
}

