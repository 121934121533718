export class PickHistory {

    constructor(id: number, ticketHolderId: number, name: string, quantity: number, schedule_id: number, date: Date, mascot: string) {
        this.id = id;
        this.ticketHolderId = ticketHolderId;
        this.name = name;
        this.quantity = quantity;
        this.schedule_id = schedule_id;
        this.date = date;
        this.mascot = mascot;
    }

    id: number;
    ticketHolderId: number;
    name: string;
    quantity: number;
    schedule_id: number;
    date: Date;
    mascot: string;

    gameDate() : Date {
        return new Date(this.date);
    }
}