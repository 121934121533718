import { Component, OnInit } from '@angular/core';
import { AdminService } from '../admin/admin.service';
import { PickChannelService } from '../channels/pick-channel.service';
import { PickingChannelService } from '../channels/picking-channel.service';

@Component({
  selector: 'app-picking',
  templateUrl: './picking.component.html',
  styleUrls: ['./picking.component.css']
})
export class PickingComponent implements OnInit {

  constructor(
    private _pickChannelService: PickingChannelService,
    private _generalChannelService: PickChannelService,
    private _adminApi: AdminService
    ) { }
  picking: any;
  onDeck: any;
  private pickChannel: any;
  private generalChannel: any;
  messages: any[] = [];
  panelOpenState: Boolean = false;

  ngOnInit() {
    this.setupGeneralChannel();

    this._pickChannelService.currentPicker$.subscribe(
      currentPicker => {
        if(this.getRemaingPicks(currentPicker) == 0)
        {
          this._adminApi.skipPicker();
        }else {
          this.picking = currentPicker;
        }
      });

    this._pickChannelService.onDeckPicker$.subscribe(
      onDeckPicker => {
        if(onDeckPicker.id == this.picking.id && this.picking.ticketsRemaining() == 2){
          this._adminApi.skipPicker();
        }else {
          this.onDeck = onDeckPicker;
        }
      });
  }


  setupGeneralChannel() {
    this.generalChannel = this._generalChannelService.getGeneralChannel();
    this.generalChannel.join().subscribe((response: any) => {
    });
    this.generalChannel.observeMessage('shout').subscribe((response: any) => {
      this.messages.push(response);
    });
  }

  togglePanel() {
    this.panelOpenState = (this.messages.length > 0 && !this.panelOpenState);
  }

  clearMessages() {
    this.messages = [];
    this.panelOpenState = false;
  }

  getRemaingPicks(user: any) {
    if (user) {
      return user.allowed_picks - user.picks_made;
    }
    else {
      return 0;
    }
  }
}
