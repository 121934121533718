<app-picking></app-picking>
<div class="container">
<div >
  <p>
    <button mat-button mat-raised-button (click)="startHopper()">Start Hopper</button>
  </p>
  <p>
    <button mat-button mat-raised-button (click)="skipPicker()">Skip Picker</button>
  </p>

  <mat-form-field *ngIf="picker">
    <input matInput placeholder="user name" matInput [matAutocomplete]="autoAdminUser" [ngModelOptions]="{standalone: true}"
      [ngModel]="picker.first_name">
    <mat-autocomplete #autoAdminUser="matAutocomplete">
      <mat-option *ngFor="let option of pickers" (onSelectionChange)="updatePicker(option)" [value]="option">{{option.first_name}}</mat-option>
    </mat-autocomplete>
  </mat-form-field>

  <p> <button mat-button mat-raised-button (click)="queuePicker()">Queue Picker</button>
  </p>

  <p>Pick Count: {{pickCount}}</p>
  <p *ngIf="game">Game: {{game.team_city}} - {{game.gameDate() | date : 'EEE MMM dd h:mm aa'}} with
    {{game.ticketsRemaining()}} tickets left</p>

    <mat-form-field>
      <input class="fill" matInput placeholder="Select Game" matInput [matAutocomplete]="autoGame" [ngModelOptions]="{standalone: true}"
        [ngModel]="game.team_city">
      <mat-autocomplete #autoGame="matAutocomplete" >
        <mat-option *ngFor="let game of availableGames" (onSelectionChange)="updateGame(game.schedule_id)"
         [value]="game.team_city">{{game.team_city}} - {{game.gameDate() | date : 'EEE MMM dd h:mm aa'}}</mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <mat-form-field>
      <input class="fill" matInput placeholder="Select Quantity" matInput [matAutocomplete]="autoQuantity" [ngModelOptions]="{standalone: true}"
        [(ngModel)]="pickQuantity">
      <mat-autocomplete #autoQuantity="matAutocomplete">
        <mat-option [value]="2">2</mat-option>
        <mat-option *ngIf="game.ticketsRemaining() == 4" [value]="4">4</mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <br/>

    <br/>
  <button *ngIf="currentPicker" mat-button mat-raised-button (click)="makeCurrentUserPick()">Pick for
    {{currentPicker.first_name}}</button>
  <button *ngIf="currentPicker" mat-button mat-raised-button (click)="pickAll()">Pick All</button>
  <button *ngIf="currentPicker" mat-button mat-raised-button (click)="stopAutoPicking()">Stop</button>
</div>
<!--
<div class="third">
  <table>
    <thead>
      <th>User</th>
      <th>Remaning</th>
      <th>Game</th>
      <th>Pick Count</th>
      <th>Game Closed</th>
    </thead>
    <tbody>
      <tr *ngFor="let pick of madePicks">
        <td>{{pick.name}}</td>
        <td>{{pick.picksRemaining}}</td>
        <td>{{pick.game}}</td>
        <td>{{pick.quantity}}</td>
        <td>{{pick.gameClosed}}</td>
      </tr>
    </tbody>
  </table>

</div>
<div class="third">
  <table>
    <thead>
      <th>Game</th>
      <th>Tickets Remaingin</th>
      <th>Game Closed</th>
    </thead>
    <tbody>
      <tr *ngFor="let game of availableGames">
        <td>{{game.team_city}}</td>
        <td>{{game.ticketsRemaining()}}</td>
        <td>{{game.ticketsRemaining() == 0}}</td>
      </tr>
    </tbody>
  </table>
</div>
</div>  
-->