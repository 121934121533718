import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TicketHolderService {
  constructor(private _http: HttpClient) { }
  getTicketHolders() {
    return Observable.create((o: any) => {
      this._http.get(environment.baseApi + 'ticketholder/all')
        .subscribe(
          (res: any) => {
            o.next(res['data']);
            o.complete();
          },
          err => {
            o.complete();
          });
    });
  }
}

