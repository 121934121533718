import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { User } from '../shared/user';
import { UserService } from '../shared/user.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  constructor(private auth: AuthService, private userService: UserService, private router: Router) { }
  userName: string;
  password: string;
  error: boolean = false;
  errorMessage: string = "Unable to log on.  Please check username and password.  If all else fails, ask Joey.";

  ngOnInit() {
  }
  login() {
    this.error = false;
    this.auth.authenticate(this.userName, this.password).subscribe((result) => {
      console.log(result);
      const user = new User(result, this.userName, '', '');
      this.userService.setUser(user);
      this.router.navigate(['calendar']);
    },
      err => {
        this.error = true;
      });
  }
}
