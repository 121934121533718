import { Component, OnInit } from '@angular/core';
import { AllPicksService } from './all-picks.service';
import { PicksMade } from './picks-made';
import { PickChannelService } from '../channels/pick-channel.service';

@Component({
  selector: 'app-all-picks',
  templateUrl: './all-picks.component.html',
  styleUrls: ['./all-picks.component.css']
})
export class AllPicksComponent implements OnInit {
  picks: PicksMade[];
  displayedColumns = ['name', 'remainingPicks', 'lastPick'];
  private pickChannel: any;

  constructor(private _allPicksService: AllPicksService,
    private _pickChannelService: PickChannelService) {
  }

  ngOnInit() {
    this.loadPicks();
    this.setupChannel();
  }

  setupChannel() {
    this.pickChannel = this._pickChannelService.getPickChannel();
    this.pickChannel.join().subscribe((response: any) => {
    });
    this.pickChannel.observeMessage('all_picks').subscribe((response: any) => {
      this.picks = response.message;
    });
  }

  loadPicks() {
    this._allPicksService.getAllPicks().
      subscribe(result => this.picks = result);
  }

  remainingPicks(pickMade: PicksMade): string {
    return (pickMade.total_tickets - pickMade.picks_made) + ' of ' + pickMade.total_tickets;
  }

  lastPick(pickMade: PicksMade): string {
    if (pickMade.last_pick !== 0) {
      return pickMade.last_pick_made + ', rd. ' + pickMade.last_pick;
    } else {
      return '';
    }
  }
}

