import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Filter } from './filter';

@Injectable({
  providedIn: 'root'
})
export class FilterService {

  filter = new BehaviorSubject<Filter>(new Filter());
  constructor() { }


  public setFilter(filter: Filter) {
    this.filter.next(filter);
  }

  public getFilter(): Observable<Filter> {
    return this.filter.asObservable();
  }
}
