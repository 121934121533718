export class Filter
{
    constructor(){
        this.months.push(new FilterOption(9,'OCT'));
        this.months.push(new FilterOption(10, 'NOV'));
        this.months.push(new FilterOption(11,'DEC'));
        this.months.push(new FilterOption(0,'JAN'));
        this.months.push(new FilterOption(1,'FEB'));
        this.months.push(new FilterOption(2,'MAR'));
        this.months.push(new FilterOption(3,'APR'));

        this.daysOfWeek.push(new FilterOption(1,'M'));
        this.daysOfWeek.push(new FilterOption(2,'T'));
        this.daysOfWeek.push(new FilterOption(3,'W'));
        this.daysOfWeek.push(new FilterOption(4,'T'));
        this.daysOfWeek.push(new FilterOption(5,'F'));
        this.daysOfWeek.push(new FilterOption(6,'S'));
        this.daysOfWeek.push(new FilterOption(0,'S'));

        this.currentDate = new Date();
        this.currentDate.setHours(0,0,0,0); 
        
        this.onlyShowAvailable = false;
        this.onlyShowUnplayed = true;
        this.onlyShowMyGames = false;

    }

    onlyShowAvailable : boolean;
    onlyShowUnplayed : boolean;
    onlyShowMyGames : boolean;
    months : FilterOption[]= [];
    daysOfWeek : FilterOption[] = [];
    currentDate : Date;

    reset(): void {
        this.months.forEach(month => month.selected = false);
        this.daysOfWeek.forEach(day => day.selected = false);
        this.onlyShowAvailable = false;
        this.onlyShowUnplayed = true;
        this.onlyShowMyGames = false;
    }
}

export class FilterOption{

    constructor(value : number, display : string ){
        this.value = value;
        this.display = display;
    }

    value : number;
    display : string;
    selected : boolean = false;
}
