import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams, HttpHeaders } from '@angular/common/http';
import { Game } from '../game/game';
import { Observable, BehaviorSubject } from 'rxjs';
import { tap, map } from 'rxjs/operators';
import { Pick } from '../game/pick';
import { environment } from '../../environments/environment';
@Injectable({
  providedIn: 'root'
})

export class CalendarService {

  private _scheduleApi: string = environment.baseApi + 'schedule';

  constructor(private _http: HttpClient) { }

  getSchedule(): Observable<Game[]> {
    //TODO - Add some error handling
    return this._http.get(this._scheduleApi)
      .pipe(
        tap(response => console.log(response)),
        map((response: any) => {

          const games: Game[] = [];
          const data = response["data"] as Game[];
          data.forEach((game) => {
            const newGame = (new Game(game.game_dttm, game.team_city, game.schedule_id, [], game.mascot, game.is_premier, game.division, game.conference));
            newGame.picks = [];
            const picks = game.picks as Pick[];
            picks.forEach((pick) => {
              const newPick = new Pick(pick.quantity, pick.last_name, pick.first_name);
              newGame.picks.push(newPick);
            });
            games.push(newGame);
          });

          return games;
        })
      )
  }
}
