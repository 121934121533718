import { Injectable } from '@angular/core';
import { HttpClient, } from '@angular/common/http';
import { Game } from '../game/game';
import { Observable, } from 'rxjs';
import { tap, map } from 'rxjs/operators';
import { Pick } from '../game/pick';
import { environment } from '../../environments/environment';
import { PickHistory } from './pick-history';
@Injectable({
  providedIn: 'root'
})

export class HistoryService {

  private _historyApi: string = environment.baseApi + '/pick/pickshistory/';

  constructor(private _http: HttpClient) { }

  getHistory(): Observable<PickHistory[]> {
    //TODO - Add some error handling
    return this._http.get<PickHistory[]>(this._historyApi)
      .pipe(
        tap(response => console.log(response)),
        map((response: any) => {

          const picks: PickHistory[] = [];
          const data = response["data"] as any[];
          data.forEach((pick: any) => {
            const pickHistory = (new PickHistory(pick.id, pick.ticket_holder_id, pick.name, pick.quantity, pick.schedule_id, pick.date, pick.mascot));
            picks.push(pickHistory);
          });

          return picks;
        })
      )
  }
}
