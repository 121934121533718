<mat-toolbar color="primary" class="toolbar">
        <button mat-icon-button (click)="snav.toggle()">
            <mat-icon>menu</mat-icon>
        </button>
        <div class="app-name">
            <!--<img class='logo' src='/assets/thunder.png'/> -->
            {{title}}
        </div>
        <span class='spacer'></span>
        <mat-icon >person</mat-icon>
        <span *ngIf='currentUser !== null'>{{currentUser.first_name}}</span>
    </mat-toolbar>
<div class="container" [class.is-mobile]="mobileQuery.matches">
   

    <mat-sidenav-container class="sidenav-container">
        <mat-sidenav #snav>
            <mat-nav-list>
                    <a (click)="snav.toggle()" mat-list-item routerLink="login" routerLinkActive="active">Login</a>
                    <a (click)="snav.toggle()" mat-list-item routerLink="calendar">Calendar</a>
                    <a (click)="snav.toggle()" mat-list-item routerLink="allpicks">Picks Remaining</a>
                    <a (click)="snav.toggle()" mat-list-item routerLink="history">Picks History</a>
                    <a (click)="snav.toggle()" mat-list-item routerLink="Admin" *ngIf="userIsAdmin()">Admin</a>
            </mat-nav-list>
        </mat-sidenav>
        
        <mat-sidenav-content class="main">
            <router-outlet></router-outlet>
        </mat-sidenav-content>
    </mat-sidenav-container>
</div>
