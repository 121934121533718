import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AdminService {
  private _adminApi = environment.baseApi + 'admin';
  constructor(private _http: HttpClient) { }

  startHopper() {
    return Observable.create((o: any) => {
      this._http.post(this._adminApi + '/starthopper', {})
        .subscribe(
          (res: any) => {
            o.next(res);
            o.complete();
          },
          err => {
            o.complete();
          });
    });
  }

  skipPicker() {
    return Observable.create((o: any) => {
      this._http.post(this._adminApi + '/skip_picker', {})
        .subscribe(
          (res: any) => {
            o.next(res);
            o.complete();
          },
          err => {
            o.complete();
          });
    });
  }
  queuePicker(ticketHolderId: number) {
    return Observable.create((o: any) => {
      this._http.post(this._adminApi + '/queue_picker', { ticket_holder_id: ticketHolderId })
        .subscribe(
          (res: any) => {
            o.next(res);
            o.complete();
          },
          err => {
            o.complete();
          });
    });
  }
}

