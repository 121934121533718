import { Injectable } from '@angular/core';
import { User } from './user';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { environment } from '../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private currentUser = new BehaviorSubject<User>(new User(0, '', '', ''));
  constructor(private http: HttpClient) {
    if (this.currentUser.value === null || this.currentUser.value.id === 0) {
      //@ts-ignore
      let userData = sessionStorage.getItem('currentUser')?.toString();
      if (userData && userData !== null) {
        let user = JSON.parse(userData)?.toString();
        this.currentUser.next(user);
      }
    }
   }

  setUser(user: User): void {
    this.loadAllUserInfo(user.username).subscribe((result: User) => {
      this.currentUser.next(result);
      window.sessionStorage.setItem('currentUser', JSON.stringify(result));
    })

    this.currentUser.next(user);
    window.sessionStorage.setItem('currentUser', JSON.stringify(user));
  }

  getUser(): Observable<User> {


    return this.currentUser.asObservable();
  }

  private loadAllUserInfo(username: string): Observable<User> {
    return this.http.get<User>(environment.userInfoApiUrl, { params: { 'username': username } })
  }
}
