import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HopperChannel } from './hopper-channel.service';
import { environment } from '../../environments/environment';
import * as Phoenix from 'phoenix';

@Injectable({
    providedIn: 'root'
})
export class PickChannelService {
    private channelService: HopperChannel;

    private socket: any;
    private pick_topic = 'hopper:picking';
    private general_topic = 'hopper:general';
    private user_topic = 'hopper:user';

    constructor() {
        this.socket = new Phoenix.Socket(environment.pick_socket, {
        });
    }
    getGeneralChannel() {
        return new HopperChannel(this.socket, this.general_topic);
    }
    getPickChannel() {
        return new HopperChannel(this.socket, this.pick_topic);
    }
    getUserChannel(user_id: any) {
        return new HopperChannel(this.socket, this.user_topic + user_id);
    }
}
