<div class='wrapper'>
    <div class='header'>
        <app-picking></app-picking>
    </div>
    <div class='content'>
        <div class='game' *ngFor="let game of games">
            <app-game [game]='game' (onGameChanged)="gameChanged($event)"></app-game>
        </div>
        <div>
            <h4 *ngIf='games == undefined || games.length === 0'>No games for selected filter.</h4>
        </div>
    </div>
</div>
<div class='footer'>
    <mat-toolbar color='primary'>
        <mat-toolbar-row>
            <a [href]="downloadCalendar" mat-icon-button>
                <mat-icon>
                date_range
                </mat-icon>
            </a>
            <span class='spacer'></span>
            <button mat-icon-button (click)='openFilter()'>
                <mat-icon>filter_list</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>
</div>