import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import * as Phoenix from 'phoenix';


export class HopperChannel {

  public socket: Phoenix.Socket;
  public channel: Phoenix.Channel;
  public topic: string;

  constructor(socket: Phoenix.Socket, topic: string, params = {}) {
    this.socket = socket;
    this.topic = topic;
    this.channel = this.socket.channel(topic, params);
    this.socket.connect();
  }

  join() {
    const joined = this.channel.join();
    return new Observable((observer) => {
      joined
        .receive('ok', response => {
          console.log('connected', response);
          observer.next(response);
        })
        .receive('error', response => { observer.error(response); });
    });
  }

  observeMessage(message: any) {
    return new Observable((observer) => {
      this.channel.on(message, (response) => {
        console.log(message, response);
        observer.next(response);
      });
    });
  }
}
