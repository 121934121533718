<div class='container'>
  <form #loginForm (ngSubmit)="login()" class="form-container">
    <mat-form-field appearance="fill">
      <mat-icon matPrefix class='icon'>person</mat-icon>
      <mat-label>Username</mat-label>
      <input matInput name="userName" placeholder="Username" type="text" [(ngModel)]="userName" />
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-icon matPrefix class='icon'>lock</mat-icon>
      <mat-label>Password</mat-label>
      <input matInput id="password" name="password" placeholder="Password" type="password" [(ngModel)]="password" />
        <mat-icon matSuffix class="toggle" onclick="if (password.type == 'text') password.type = 'password';
          else password.type = 'text';">remove_red_eye</mat-icon>
    </mat-form-field>
    <button mat-raised-button>Login</button>
    <mat-error class='error' *ngIf="error">{{errorMessage}}</mat-error>
  </form>
</div>