
<table mat-table [dataSource]="picks">

    <!--- Note that these columns can be defined in any order.
          The actual rendered columns are set as a property on the row definition" -->
  
    <!-- Position Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef> Name </th>
      <td mat-cell *matCellDef="let pick"> {{pick.name}} </td>
    </ng-container>
  
    <!-- Name Column -->
    <ng-container matColumnDef="remainingPicks">
      <th mat-header-cell *matHeaderCellDef> Remaining Picks  </th>
      <td mat-cell *matCellDef="let pick"> {{remainingPicks(pick)}} </td>
    </ng-container>
  
    <!-- Weight Column -->
    <ng-container matColumnDef="lastPick">
      <th mat-header-cell *matHeaderCellDef> Last Pick </th>
      <td mat-cell *matCellDef="let pick"> {{lastPick(pick)}} </td>
    </ng-container>
  
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

