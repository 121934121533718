import { Injectable } from '@angular/core';
import { PicksMade } from './picks-made';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import {map, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AllPicksService {
  private _api : string = environment.baseApi + 'pick/picksmade';
  constructor(private _http :HttpClient) { }

  getAllPicks() : Observable<PicksMade[]>
  {
    return this._http.get<PicksMade[]>(this._api).pipe(
  
      tap(response => console.log(response)),
      // Put this back in when Bradley adds Data tag
      map(response => response as PicksMade[] 
      )
    )
  }
}
