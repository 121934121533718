<mat-card>
  <mat-card-header>
    <mat-card-title>
      Filter Games
    </mat-card-title>
  </mat-card-header>
  <mat-divider></mat-divider>
  <mat-card-content>
    <div class='filter-container'>
      <div class='search-option'>
        <span class='spacer'></span>
        <span mat-line>
          <mat-slide-toggle (change)='updateAvailable($event.source.checked)' [checked]='filter.onlyShowAvailable'>Show
            Available Games Only</mat-slide-toggle>
        </span>
      </div>
      <div class='search-option'>
        <span class='spacer'></span>
        <span mat-line>
          <mat-slide-toggle (change)='updateUnplayed($event.source.checked)' [checked]='filter.onlyShowUnplayed'>Show
            Unplayed Games Only</mat-slide-toggle>
        </span>
      </div>
      <div class='search-option'>
        <span class='spacer'></span>
        <span mat-line>
          <mat-slide-toggle (change)='updateMyGames($event.source.checked)' [checked]='filter.onlyShowMyGames'>Show My
            Games Only</mat-slide-toggle>
        </span>
      </div>
      <div class='search-option'>
        <div>Month</div>
        <div>
          <mat-button-toggle-group class="month-group" multiple aria-label="Month">
            <mat-button-toggle *ngFor='let month of filter.months | slice:0:4;' [name]='month.value.toString()'
              [checked]='month.selected' (change)='updateSelectedMonth(month.value, $event.source.checked)'
              [value]='month.value'>{{month.display}}</mat-button-toggle>

          </mat-button-toggle-group>
        </div>
        <div>
          <mat-button-toggle-group multiple aria-label="Month">
            <mat-button-toggle *ngFor='let month of filter.months | slice:4;' [name]='month.value.toString()'
              [checked]='month.selected' (change)='updateSelectedMonth(month.value, $event.source.checked)'
              [value]='month.value'>{{month.display}}</mat-button-toggle>

          </mat-button-toggle-group>
        </div>
      </div>
      <div class='search-option'>
        <div>Day of Week</div>
        <div>
          <mat-button-toggle-group matline multiple name="dayOfWeek" aria-label="Day of Week">
            <mat-button-toggle *ngFor='let day of filter.daysOfWeek' [checked]='day.selected'
              (change)='updateSelectedDay(day.value, $event.source.checked)' value='day.value'>{{day.display}}
            </mat-button-toggle>
          </mat-button-toggle-group>
        </div>
      </div>
    </div>
  </mat-card-content>

  <mat-card-actions>
    <div class='action-row'>
      <span class='spacer'></span>
      <button mat-raised-button (click)='close()'>
        Done
      </button>
      <button mat-raised-button (click)='clear()'>
        Clear
      </button>
    </div>
  </mat-card-actions>
</mat-card>
