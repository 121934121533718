import { Component, ViewContainerRef, OnInit, Output } from '@angular/core';
import { CalendarService } from './calendar.service';
import { Game } from '../game/game';
import { PickChannelService } from '../channels/pick-channel.service';

import { map } from 'rxjs/operators';
import { FilterComponent } from './filter/filter.component';
import { FilterService } from './filter/filter.service';
import { Filter, FilterOption } from './filter/filter';
import { UserService } from '../shared/user.service';
import { User } from '../shared/user';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-season-calendar',
  templateUrl: './season-calendar.component.html',
  styleUrls: ['./season-calendar.component.css']
})
export class SeasonCalendarComponent implements OnInit {

  games: Game[]
  allGames: Game[];
  pickChannel: any;

  whos_up: String;
  filter: Filter;
  userChannel: any;
  messages: any[] = [];
  currentUser: User;
  downloadCalendar: any;
  constructor(private _calendarService: CalendarService,
    private _pickChannelService: PickChannelService,
    private _snackBar: MatSnackBar,
    private _bottomSheet: MatBottomSheet,
    private _filterService: FilterService,
    private _userService: UserService) {

  }

  ngOnInit() {
    this.filter = new Filter();
    this.getUser();
    this.loadGames();
    this.setupChannel();
    this.watchFilter();
    this.downloadCalendar = environment.baseApi + '/schedule/get_calendar?ticket_holder_id=' + this.currentUser.id;
  }

  setupChannel() {
    this.userChannel = this._pickChannelService.getUserChannel(this.currentUser.id);
    this.userChannel.join().subscribe((response: any) => {
      console.log(response);
    });
    this.userChannel.observeMessage("whisper").subscribe((response: any) => {
      this._snackBar.open(response.message, "OK", { verticalPosition: 'top' });
    });
  }

  getUser() {
    this._userService.getUser().subscribe(result => {
      this.currentUser = result
    });
  }

  loadGames() {
    this._calendarService.getSchedule()
      .subscribe(result => {
        this.games = result.sort(this.compareDates);
        this.allGames = this.games;
        this.filterGames(this.filter);
      });
  }

  gameChanged(changedInfo: any) {
    const index = this.allGames.findIndex(game => game.schedule_id == changedInfo.schedule_id)
    this.allGames[index].picks = changedInfo.picks;
    this.filterGames(this.filter);
  }

  compareDates(a: Game, b: Game) {
    return Date.parse(a.game_dttm) - Date.parse(b.game_dttm);
  }

  openFilter(): void {
    this._bottomSheet.open(FilterComponent, { data: { filter: this.filter } });
  }

  watchFilter(): void {
    this._filterService.getFilter().subscribe(filter => {
      if (filter != null) {
        this.filterGames(filter);
      }
    })
  }

  private filterGames(filter: Filter) {
    this.filter = filter;
    let filteredGames: Game[] = [];
    let workingGames = this.allGames;


    const months = filter.months.filter((month) => { return month.selected === true; })
    const days = filter.daysOfWeek.filter((day) => { return day.selected === true; })

    let monthGames: Game[] = [];
    if (months.length > 0) {
      months.forEach(month => {
        const gamesForSelectedMonth = workingGames.filter((game) => { return game.gameDate().getMonth() === month.value; });
        gamesForSelectedMonth.forEach(game => monthGames.push(game));
      })
    } else {
      monthGames = workingGames;
    }

    if (days.length > 0) {
      days.forEach(day => {
        const gamesForDay = monthGames.filter((game) => game.gameDate().getDay() === day.value)
        gamesForDay.forEach(game => filteredGames.push(game));
      })
    } else {
      filteredGames = monthGames;
    }


    if (filter.onlyShowAvailable) {
      filteredGames = filteredGames.filter(game => {
        return game.ticketsRemaining() > 0;
      })
    }

    if (filter.onlyShowUnplayed) {
      filteredGames = filteredGames.filter(game => {
        return game.gameDate() >= filter.currentDate;

      });
    }

    if (filter.onlyShowMyGames) {
      filteredGames = filteredGames.filter(game => {
        return game.picks.filter(pick => {
          return pick.first_name == this.currentUser.first_name && pick.last_name == this.currentUser.last_name;
        }).length > 0;
      });
    }

    this.games = filteredGames;

    //Scroll to top of filtered games;
    window.scroll(0, 0);
  }

  private findSelected(option: FilterOption) {
    return option.selected == true;
  }
}
