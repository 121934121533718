import { last } from "rxjs/operators";

export class Pick {
    constructor(quantity : number, last_name : string, first_name : string)
    {
        this.quantity = quantity;
        this.last_name = last_name;
        this.first_name = first_name;
    }
    quantity: number;
    last_name: string;
    first_name: string;
    
    displayName() : string{
        return  this.first_name + ' ' + this.last_name;
    }
}