import { Injectable, NgZone } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Router, UrlSegment } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';
import { UserService } from '../shared/user.service';
import { environment } from '../../environments/environment';



@Injectable()
export class AuthService {

  _tokenSubject: any;
  _jwtHelper = new JwtHelperService();

  private _authApi = environment.baseApi + 'auth/identity/callback';
  constructor(private router: Router, private ngZone: NgZone, private _http: HttpClient) {

  }
  canAccessRoute(url: UrlSegment[]): any {
    return true;
  }

  getUpdatedToken(): Observable<string> {
    return this._tokenSubject.asObservable();
  }

  isLoggedIn(): boolean {
    return this.getAccessToken() !== null && !this.isTokenExpired();
  }

  isTokenExpired() {
    return false;
    console.log(this.getAccessToken());
    return this._jwtHelper.isTokenExpired(this.getAccessToken());
  }

  storeToken(token: any) {
    window.localStorage.setItem('token', token);
  }

  getToken(): any {
    return window.localStorage.getItem('token');
  }

  getAccessToken() {
    return this.getToken();
  }

  getIdToken() {
    const token = this.getToken();
    if (token) {
      return token;
    }
    return null;
  }

  isAdmin() {
    const token = this._jwtHelper.decodeToken(this.getIdToken());
    return token.admin;
  }

  getRefreshToken() {
    return this.getToken(); //['refresh_token'];
  }

  startAuthentication() {
  }

  authenticate(userName: string, password: string) {
    return this._http.post<any>(this._authApi, { user: { 'username': userName, 'password': password } })
      .pipe(
        map((res) => {
          const user = res['data']['user'];
         /*
          console.log(token);
          const decodedToken = this._jwtHelper.decodeToken(token);
          console.log(decodedToken);
          this.storeToken(token);
          */
          return user;
        }));
  }
}
