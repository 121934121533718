<app-picking></app-picking>

<table mat-table [dataSource]="pickHistory">

    <!--- Note that these columns can be defined in any order.
          The actual rendered columns are set as a property on the row definition" -->
  
    <!-- Position Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef> Name </th>
      <td mat-cell *matCellDef="let pick"> {{pick.name}} </td>
    </ng-container>
  
    <!-- Name Column -->
    <ng-container matColumnDef="quantity">
      <th mat-header-cell *matHeaderCellDef> Quantity </th>
      <td mat-cell *matCellDef="let pick"> {{pick.quantity}} </td>
    </ng-container>
  
    <!-- Weight Column -->
    <ng-container matColumnDef="game">
      <th mat-header-cell *matHeaderCellDef> Game </th>
      <td mat-cell *matCellDef="let pick">
        {{pick.mascot}} - {{pick.gameDate() | date : 'EEE MMM dd h:mm aa'}}
      </td>
    </ng-container>
  
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
