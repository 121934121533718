import { Component, ChangeDetectorRef, OnInit, OnDestroy } from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';

import { UserService } from './shared/user.service';
import { User } from './shared/user';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'Thunder Hopper';
  currentUser: User;

  mobileQuery: MediaQueryList;

  private _mobileQueryListener: () => void;

  constructor(changeDetectorRef: ChangeDetectorRef, media: MediaMatcher, private userService: UserService) {
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  ngOnInit() {
    this.getUser();
  }

  getUser() {
    this.userService.getUser().subscribe(result => {
      this.currentUser = result;
    });
  }

  userIsAdmin(): boolean{
    if(this.currentUser){
      const index = environment.admin_users.indexOf(this.currentUser.first_name);
      return index >= 0;
    }
    return false;
  }

  ngOnDestroy(): void {
     this.mobileQuery.removeListener(this._mobileQueryListener);
  }
}
