<mat-toolbar>
  <mat-toolbar-row >
      <button mat-icon-button (click)='togglePanel()'>
          <mat-icon matBadgePosition="after" matBadgeColor="warn" 
          matBadge="{{messages.length > 0 ? messages.length : ''}}">announcement</mat-icon>
      </button>
      <mat-icon>query_builder</mat-icon>
      <span matBadgePosition="after" matBadgeColor="warn" matBadge="{{getRemaingPicks(picking)}}">{{picking?.first_name}}</span>
      <span class='spacer'></span> 
      <mat-icon>timer</mat-icon>
      <span matBadgePosition="after" matBadgeColor="warn"  matBadge="{{getRemaingPicks(onDeck)}}">{{onDeck?.first_name}}</span>
    </mat-toolbar-row>  
  </mat-toolbar>
  <mat-expansion-panel  [expanded]="panelOpenState">
      <mat-card>
        <mat-card-header>
          <mat-card-title><strong>Announcements</strong></mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <mat-list *ngFor="let m of messages">
                <mat-list-item>{{m.message}}</mat-list-item>
                <mat-divider></mat-divider>
          </mat-list>
        </mat-card-content>
      <mat-card-actions>
          <button mat-raised-button color="warn" (click) = "clearMessages()">
            <mat-icon>delete_forever</mat-icon>Clear
          </button>        
      </mat-card-actions>
    </mat-card>
  </mat-expansion-panel>
