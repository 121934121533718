import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { HopperChannel } from './hopper-channel.service';
import { environment } from '../../environments/environment';
import { Socket } from 'phoenix';

@Injectable({
  providedIn: 'root'
})
export class PickingChannelService {
  private channelService: HopperChannel;
  private socket: any;

  private currentPickerSource = new Subject<any>();
  private onDeckPickerSource = new Subject<any>();

  currentPicker$ = this.currentPickerSource.asObservable();
  onDeckPicker$ = this.onDeckPickerSource.asObservable();

  constructor() {
    this.socket = new Socket(environment.pick_socket, {});
    this.channelService = new HopperChannel(this.socket, "hopper:picking");
    this.channelService.join().subscribe((res) => { });
    this.channelService.observeMessage('whos_up').subscribe((res: any) => {
      const response: any = res;
      const current = response.message[0];
      const onDeck = response.message[1];
      this.currentPickerSource.next(current);
      this.onDeckPickerSource.next(onDeck);
    });
  }
}  
