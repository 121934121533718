import { Component, OnInit, Inject } from '@angular/core';
import { SeasonCalendarComponent } from '../season-calendar.component';
import { FilterService } from './filter.service';
import { Filter } from './filter';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.css']
})
export class FilterComponent implements OnInit {

  constructor(private _bottomSheetRef: MatBottomSheetRef<SeasonCalendarComponent>,
    private _filterService: FilterService,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any) {
    this.filter = data.filter;
  }
  filter: Filter = new Filter();

  ngOnInit() {
  }

  updateFilter(): void {
    this._filterService.setFilter(this.filter);
  }

  updateAvailable(onlyShowAvailable: boolean) {
    this.filter.onlyShowAvailable = onlyShowAvailable;
    this.updateFilter();
  }

  updateUnplayed(onlyShowUnplayed: boolean) {
    this.filter.onlyShowUnplayed = onlyShowUnplayed;
    this.updateFilter();
  }

  updateMyGames(onlyShowMyGames: boolean) {
    this.filter.onlyShowMyGames = onlyShowMyGames;
    this.updateFilter();
  }

  updateSelectedMonth(monthValue: number, selected: boolean) {
    this.filter.months.filter(month => month.value == monthValue)[0].selected = selected;
    this.updateFilter();
  }

  updateSelectedDay(dayValue: number, selected: boolean): void {
    this.filter.daysOfWeek.filter(day => day.value == dayValue)[0].selected = selected;
    this.updateFilter();
  }

  close(): void {
    this._bottomSheetRef.dismiss();
  }

  clear(): void {
    this.filter.reset();
    this.updateFilter();
  }

}
