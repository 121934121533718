import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { SeasonCalendarComponent } from './season-calendar/season-calendar.component';
import { MaterialModule } from './material/material.module';
import { GameComponent } from './game/game.component';
import { CalendarService } from './season-calendar/calendar.service';
import { LoginComponent } from './login/login.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { AllPicksComponent } from './all-picks/all-picks.component';
import { AllPicksService } from './all-picks/all-picks.service';
import { PickChannelService } from './channels/pick-channel.service';
import { PickingChannelService } from './channels/picking-channel.service';
import { TokenInterceptor } from './auth/token-interceptor';
import { AuthService } from './auth/auth.service';
import { AuthGuard } from './auth/auth-guard.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AdminComponent } from './admin/admin.component';
import { PickingComponent } from './picking/picking.component';
import { FilterComponent } from './season-calendar/filter/filter.component';
import { PickService } from './game/pick.service';
import { FilterService } from './season-calendar/filter/filter.service';
import { PickStatusComponent } from './pick-status/pick-status.component';
import { HistoryComponent } from './history/history.component';
import { HistoryService } from './history/history.service';

const appRoutes: Routes = [
  { path: 'calendar', component: SeasonCalendarComponent, canActivate: [AuthGuard] },
  { path: 'login', component: LoginComponent },
  { path: 'allpicks', component: AllPicksComponent },
  { path: 'history', component: HistoryComponent },
  { path: 'Admin', component: AdminComponent, canActivate: [AuthGuard] },
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  declarations: [
    AppComponent,
    SeasonCalendarComponent,
    GameComponent,
    LoginComponent,
    PageNotFoundComponent,
    AllPicksComponent,
    AdminComponent,
    PickingComponent,
    PickStatusComponent,
    FilterComponent,
    HistoryComponent
  ],
  imports: [
    RouterModule.forRoot(
      appRoutes),
    BrowserModule,
    MaterialModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  entryComponents: [FilterComponent],
  providers: [CalendarService, PickChannelService, PickingChannelService, AllPicksService, AuthService, AuthGuard, FilterService, HistoryService, {
    provide: HTTP_INTERCEPTORS,
    useClass: TokenInterceptor,
    multi: true
  }, PickService],
  bootstrap: [AppComponent]
})
export class AppModule { }
