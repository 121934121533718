import { Component, OnInit } from '@angular/core';
import { AdminService } from './admin.service';
import { TicketHolderService } from '../game/ticket-holder.service';
import { PickingChannelService } from '../channels/picking-channel.service';
import { CalendarService } from '../season-calendar/calendar.service';
import { Game } from '../game/game';
import { PickService } from '../game/pick.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})

export class AdminComponent implements OnInit {
  pickers: any[] = [];
  picker: any;
  currentPicker: any;
  games: Game[];
  availableGames: Game[] = [];
  game: any;
  picks: any[] = [{ username: undefined, quantity: undefined, isPicking: true }];
  stopPicking: boolean = false;
  pickQuantity: number = 2;

  madePicks: any[] = [];
  
  constructor(private adminApi: AdminService
            , private _ticketHolderService: TicketHolderService
            , private _pickChannelService: PickingChannelService 
            , private _calendarService: CalendarService
            , private _pickService : PickService
            , private _snackBar: MatSnackBar
            ) { }

  ngOnInit() {
    this._ticketHolderService.getTicketHolders().subscribe(
      (ticketHolders: any) => {
        this.pickers = ticketHolders;
      });

      this._pickChannelService.currentPicker$.subscribe((picker: any) => {
        if(!this.picker){
          this.picker = picker;
        }
        this.currentPicker = picker;
      });

      this.loadGames();
  }

  loadGames() : void {
    this._calendarService.getSchedule()
      .subscribe(result => {
        this.games = result.sort(this.compareDates);
        this.availableGames = this.games.filter(g => g.ticketsRemaining() >= 2);
        this.loadNextGame();
      });

  }
  loadNextGame()  : void {
    //let gameIndex = Math.floor(Math.random() * this.availableGames.length)

    this.game = this.availableGames[0];
  }

  compareDates(a: Game, b: Game) {
    return Date.parse(a.game_dttm) - Date.parse(b.game_dttm);
  }


  startHopper() {
    this.pickCount = 0;
    this.stopPicking = false;
    this.adminApi.startHopper().subscribe((data: any) => {
      this.loadGames();
    });
  }

  skipPicker() {
    this.adminApi.skipPicker().subscribe((data: any) => {
    });
  }

  queuePicker() {
    this.adminApi.queuePicker(this.picker.id).subscribe((data: any) => {
    });
  }

  displayFn(ticketHolder?: any): string | undefined {
    return ticketHolder ? ticketHolder.first_name : undefined;
  }


  pickCount: number = 0;

  makeCurrentUserPick() : void {
      const savedPicks: any[] = this.picks.map(pick => {
        return {
          name: this.currentPicker.username,
          quantity: this.pickQuantity.toString(),
          isPicking: true
        };
      });

      this.madePicks.push({name: this.currentPicker.username, picksRemaining:  this.currentPicker.allowed_picks - this.currentPicker.picks_made
        , game: `${this.game.team_city} - ${this.game.game_dttm}`, quantity: 2, gameClosed: this.game.ticketsRemaining() == 2 ? true : false})

      this._pickService.savePicks(this.game.schedule_id, savedPicks).subscribe(
        {
          next: () => {
            this.pickCount += this.pickQuantity;
            this.loadGames();
          },
          err: (err: any) => {
            this._snackBar.open(err.error.message, 'OK', { verticalPosition: 'top' });
          }
        });
  }

  pickAll() : void {
    if(!this.stopPicking && this.availableGames.length > 0 && this.game){
      setTimeout(() =>
      {
        this.makeCurrentUserPick();
        this.pickAll();
      }, 1000);
    }
  }

  stopAutoPicking() : void {
    this.stopPicking = true;
  }

  updateGame(id: number) : void {
    this.game = this.availableGames.find(g => g.schedule_id == +id)
  }

  updatePicker(picker: any) : void {
    this.picker = picker;
  }

  getTeamName(scheduleId: any) : any {
    this.updateGame(+scheduleId);

    if(this.game){
      return this.game.mascot;
    }else {
      return "not found";
    }
  }
}

