import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HopperChannel } from './hopper-channel.service';
import { environment } from '../../environments/environment';
import * as Phoenix from 'phoenix';

@Injectable({
  providedIn: 'root'
})
export class GameChannelService {
  private channelService: HopperChannel;

  private socket: any;
  private topic: String = "game:";
  constructor() {
    this.socket = new Phoenix.Socket(environment.pick_socket, {
    });
  }
  getGameChannel(schedule_id: any) {
    return new HopperChannel(this.socket, this.topic + schedule_id);
  }
}  