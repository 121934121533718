import { Pick } from "./pick";

export class Game
{
   constructor(game_dttm : string, team_city : string, schedule_id : number, picks : Pick[], mascot : string,
                is_premier: boolean, division : string, conference : string)
                {
                    this.game_dttm = game_dttm;
                    this.team_city = team_city;
                    this.schedule_id = schedule_id;
                    this.picks = picks;
                    this.mascot = mascot;
                    this.is_premier = is_premier;
                    this.division = division;
                    this.conference = conference;
                }
    
    game_dttm: string;
    team_city: string;
    schedule_id: number;
    picks: Pick[];
    mascot: string;
    is_premier: boolean;
    division: string;
    conference: string;

    ticketsRemaining() : number {
        var ticketsConsumed = this.picks.reduce((a,b) => a + b.quantity,0);
        return 4 - ticketsConsumed;
    }

    gameDate() : Date {
        return new Date(this.game_dttm);
    }
}