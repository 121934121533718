<mat-card>
  <div class='game-container'>
    <div class='game-info-container'>
      <img class='logo' [src]='getImagePath()' />
      <div class='game'>
        <div class=''>
          <h2 class='game-line'>{{game.team_city | uppercase }}</h2>
          <div class='game-line'>{{game.gameDate() | date : 'EEE MMM dd h:mm aa'}}</div>
          <div class='game-line'>Tickets Remaining : {{game.ticketsRemaining()}}</div>
        </div>
      </div>
    </div>

    <div *ngIf='game.picks.length==0' class="pick-container">
      <h4>No picks selected for this game</h4>
    </div>
    <div *ngFor='let pick of game.picks'>
      <div class="pick-container">
        <h4 class="left">{{pick.displayName()}}</h4>
        <h4 class="right">Qty: {{pick.quantity}}</h4>
      </div>
    </div>
  </div>

</mat-card>
<mat-card *ngIf="game.ticketsRemaining() > 0 && userPickAvailable ">
  <button (click)="increasePicks()" *ngIf="!game.is_premier && picks.length === 1">
    <mat-icon>person_add</mat-icon>
  </button>

  <div *ngFor="let pick of picks">
    <mat-form-field *ngIf="!pick.isPicking">
      <button (click)="removePick()" matPrefix mat-button><mat-icon>person_remove</mat-icon></button>
      <input matInput placeholder="user name" matInput [matAutocomplete]="auto" [ngModelOptions]="{standalone: true}"
        [(ngModel)]="pick.username">
      <mat-autocomplete #auto="matAutocomplete">
        <mat-option *ngFor="let option of options" [value]="option.username">{{option.first_name}}</mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <mat-button-toggle-group #group="matButtonToggleGroup" [(value)]="pick.quantity">
      <mat-button-toggle value="1" *ngIf="game.ticketsRemaining() >= 1 && allowOddPicks">1</mat-button-toggle>
      <mat-button-toggle value="2" *ngIf="game.ticketsRemaining() >= 2">2</mat-button-toggle>
      <mat-button-toggle value="3" *ngIf="game.ticketsRemaining() >= 3 && !game.is_premier && allowOddPicks">3
      </mat-button-toggle>
      <mat-button-toggle value="4" *ngIf="game.ticketsRemaining() >= 4 && !game.is_premier">4</mat-button-toggle>
      <mat-button-toggle value="5" *ngIf="game.ticketsRemaining() >= 5 && !game.is_premier && allowOddPicks">5
      </mat-button-toggle>
      <mat-button-toggle value="6" *ngIf="game.ticketsRemaining() >= 6 && !game.is_premier">6</mat-button-toggle>
    </mat-button-toggle-group>
  </div>
  <button mat-button mat-raised-button [disabled]="!allowSave()" (click)="savePick()">Save</button>
  <button mat-button mat-raised-button (click)="resetPick()">Reset</button>
</mat-card>
