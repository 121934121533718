import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Game } from './game';
import { GameChannelService } from '../channels/game-channel.service';
import { PickingChannelService } from '../channels/picking-channel.service';
import { PickService } from './pick.service';
import { Pick } from './pick';
import { UserService } from '../shared/user.service';
import { TicketHolderService } from './ticket-holder.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MaterialModule } from '../material/material.module';


@Component({
  selector: 'app-game',
  templateUrl: './game.component.html',
  styleUrls: ['./game.component.css']
})
export class GameComponent implements OnInit {
  constructor(
    private _snackBar: MatSnackBar,
    private pickService: PickService,
    private _gameChannelService: GameChannelService,
    private _pickingChannelService: PickingChannelService,
    private _userService: UserService,
    private _ticketHolderService: TicketHolderService) { }

  @Input() game: Game;
  @Output() onGameChanged: EventEmitter<object> = new EventEmitter<object>();

  gameChannel: any;
  currentUser: any;
  picks: any[] = [{ username: undefined, quantity: undefined, isPicking: true }];
  userPickAvailable: boolean = false;
  options: any[] = [];
  allowOddPicks = false;

  ngOnInit() {
    this.setupGameChannel();
    this._userService.getUser().subscribe(result => {
      this.currentUser = result;
    });
    this._pickingChannelService.currentPicker$.subscribe(
      currentPicker => {
        this.determineIfUserCanPick(currentPicker);
      });
    this._ticketHolderService.getTicketHolders().subscribe(
      (ticketHolders: any) => {
        this.options = ticketHolders;
      });
  }

  setupGameChannel() {
    this.gameChannel = this._gameChannelService.getGameChannel(this.game.schedule_id);
    this.gameChannel.join().subscribe((response: any) => {
    });
    this.gameChannel.observeMessage('game_updated').subscribe((response: any) => {
      let updatePicks: any = [];
      let picks_selected = 0;
      response.message.picks.forEach((pick: Pick) => {
        updatePicks = updatePicks.concat([new Pick(pick.quantity, pick.last_name, pick.first_name)]);
        picks_selected += pick.quantity;
      });
      this.game = new Game(response.message.game_dttm, response.message.team_city,
        response.message.schedule_id, updatePicks, response.message.mascot,
        response.message.is_premier, response.message.division, response.message.conference);
      console.log(this.game);
      const changedInfo = { schedule_id: this.game.schedule_id, picks: updatePicks };

      this.onGameChanged.emit(changedInfo);
    });
  }

  determineIfUserCanPick(currentPicker: any) {
    if (this.currentUser) {
      if (currentPicker.first_name === this.currentUser.first_name) {
        this.userPickAvailable = true;
      } else if (this.currentUser.first_name === "Jeaux" || this.currentUser.first_name === "Chris") {
        // Temporary admin mode
        this.userPickAvailable = true;
      } else {
        this.userPickAvailable = false;
      }
    } else {
      this.userPickAvailable = false;
    }
  }

  displayFn(ticketHolder?: any): string | undefined {
    return ticketHolder ? ticketHolder.first_name : undefined;
  }

  increasePicks() {
    if(this.picks.length === 1){
      this.picks = this.picks.concat([{ username: undefined, quantity: undefined, isPicking: false }]);
    }
  }
  removePick(){
    this.picks.pop();
  }

  savePick() {
    const savedPicks: any[] = this.picks.map(pick => {
      return {
        name: pick.username ? pick.username : undefined,
        quantity: pick.quantity,
        isPicking: pick.isPicking
      };
    });
    //ToDo
    this.pickService.savePicks(this.game.schedule_id, savedPicks).subscribe(
      {
        next: () => {
          this.resetPick();

        },
        err: (err: any) => {
          this._snackBar.open(err.error.message, 'OK', { verticalPosition: 'top' });
        }
      });
  }
  allowSave() {
    let allow = true;
    this.picks.forEach(pick => {
      allow = allow ? pick.quantity >= 0 : false;
    });
    return allow;
  }
  
  resetPick() {
    this.picks.length = 0;
    this.picks = [{ username: undefined, quantity: undefined, isPicking: true }];
  }


  getImagePath() {
    return '/assets/' + this.game.mascot.toLowerCase() + '-logo.png';
  }
}
